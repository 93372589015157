import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button'
import Section from '../../../components/Section'
import Typography from '../../../components/Typography'
import Hero from '../../../components/Hero'
import Tabs from '../../../components/Tabs'

import Layout from '../../../layouts'
import dropdown from '../../../images/dropdown-blue.svg'

import { geoLocations, billingPaymentData } from '../../../data/billingPayment'

import '@reach/menu-button/styles.css'

const StyledMenuButton = styled(MenuButton)`
  padding: ${({ theme }) =>
    `${theme.spacing.xxs} ${theme.spacing.xl} ${theme.spacing.xxs} ${theme.spacing.xs}`};
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.colors.light.lighter.hex};
  min-width: 272px;
  border: 0;
  background-image: url(${dropdown});
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: right;
  text-align: left;

  span {
    display: none;
  }
`
const BillingPaymentPage = () => {
  const [location, setLocation] = useState('')

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Hero-Green-Energy.jpg" }) {
        extension
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>Billing and Payments</title>
        <meta name="description" content="" />
      </Helmet>
      <Hero
        image={{
          childImageSharp: data.file.childImageSharp,
          description: 'Green gradient background',
        }}
        darkText={false}
      >
        <Typography variant="h1" rendersAs="h1">
          Billing Payment FAQs{' '}
          {location && <span>for {location.replace(/_/, ' ') || ''}</span>}
        </Typography>
        <Menu>
          <StyledMenuButton>
            {location.replace(/_/, ' ') || 'Select a Location'}{' '}
            <span aria-hidden>▾</span>
          </StyledMenuButton>
          <MenuList>
            {geoLocations.map(x => (
              <MenuItem
                key={x}
                onSelect={() => setLocation(x.replace(/ /, '_'))}
              >
                {x.replace(/_/, ' ')}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Hero>

      <Section>
        {!location && (
          <Typography variant="h2">
            Select a location above to see FAQs for that region.
          </Typography>
        )}
        {location && location !== 'Select a Location' && (
          <Tabs isVertical data={billingPaymentData[location]} />
        )}
      </Section>
    </Layout>
  )
}

export default BillingPaymentPage
