import React from 'react'
import Typography from '../components/Typography'
import Link from '../components/Link'

export const geoLocations = [
  'California',
  'Delaware',
  'Illinois',
  'Indiana',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'New Jersey',
  'New York',
  'Ohio',
  'Georgia',
  'Pennsylvania',
  'Alberta',
  'British Columbia',
  'Ontario',
  'Quebec',
  'Saskatchewan',
  'Manitoba',
]

const US = [
  {
    title: 'Will my bill come directly from Just Energy?',
    content: (
      <Typography>
        In most cases, Just Energy does not bill separately from your utility
        for electricity or natural gas. Just Energy&apos;s charges will appear
        as a separate line item on your utility bill. Only customers in Texas,
        Georgia, California, and Alberta receive their bill directly from Just
        Energy.
      </Typography>
    ),
  },
  {
    title: 'Is Just Energy charging me for my supply only?',
    content: (
      <Typography>
        Your contract with Just Energy covers your electricity and/or natural
        gas supply costs and JustGreen if you choose to enroll. If you choose to
        enroll in additional contracts, such as an ecobee payment plan, you may
        see these charges on your bill as well. If your electricity and/or
        natural gas bill does not come directly from Just Energy, but from your
        local utility provider, you’ll receive a separate bill for your
        additional product contract from Just Energy.
      </Typography>
    ),
  },
  {
    title: 'Are there additional fees that my utility may be charging me?',
    content: (
      <Typography>
        In general, utility companies charge for energy distribution,
        transmission, and delivery. To learn more about what other fees they may
        apply, you should contact your utility company directly. There is no
        switching fee, however, your Just Energy rate for supply will not
        include the related charges, taxes and other amounts charged by your
        utility. These may include charges relating to transmission, delivery,
        uplift, congestion, and service charges.
      </Typography>
    ),
  },
  {
    title: 'How much will I be charged If I end my contract early?',
    content: (
      <Typography>
        The amount varies depending on your supply plan and contract length.
        Contact Just Energy for more details on cancellation fees.
      </Typography>
    ),
  },
  {
    title: 'Who do I contact with questions regarding my bill?',
    content: (
      <Typography>
        Refer to your plan’s Terms and Conditions to learn more about your
        contract. If you live in Texas, most questions regarding your bill can
        be answered simply by logging in to your Just Energy{' '}
        <a
          href="https://www.justenergy.com/myaccount"
          target="_blank"
          rel="noopener noreferrer"
        >
          online account
        </a>
        . . . You can also sign in to the Just Energy Advisor app to check your
        current account status, billing history, current charges, and other
        details. Need to speak a customer care agent directly? Call{' '}
        <a href="tel:1-866-587-8674">1-866-587-8674</a> to speak with someone
        who can help you with your account.
      </Typography>
    ),
  },
  {
    title: 'Does Just Energy use a collection agency?',
    content: (
      <Typography>
        We do our best to work with our customers to settle outstanding
        balances. However, if the balance remains unpaid for too long (length of
        time may vary by state, province, or territory) we may work with a
        collection agency to settle balances for commodity supplies and exit
        fees.
      </Typography>
    ),
  },
  {
    title:
      "What is Just Energy's mailing address for exit fees or documentation?",
    content: (
      <Typography>
        If you are sending us payment by mail, please send it to: Just Energy,
        P.O. Box 2210, Buffalo, NY 14240-2210.
      </Typography>
    ),
  },
  {
    title: 'What are some types of energy plans offered by retailers?',
    content: (
      <Typography>
        Typically, energy retailers offer price protected plans (allowing you to
        secure your commodity supply rate for a set period of time) and
        variable-rate plans (where the commodity supply rate would change with
        the changes in market prices for a set period of time).{' '}
        <Link to="/energy-explained/price-volatility">Learn more</Link> about
        the benefits and why you would be interested in one plan over the other.
      </Typography>
    ),
  },
]

const CA = [
  {
    title: 'Will I receive a bill from Just Energy?',
    content: (
      <Typography>
        No, you will continue to be billed by your utility for natural gas, the
        only difference will be that you will see Just Energy listed as your
        supplier and our toll-free phone number will appear on your bill. Click
        below for an example of where Just Energy charges could appear on your
        energy bills.
        <a href="https://www.justenergy.com/media/247588/bcsamplebill.pdf">
          BC Sample Bill
        </a>{' '}
        |{' '}
        <a href="https://justenergyweb-prd-webapp01.azurewebsites.net/Portals/0/ontariosamplebill.pdf?ver=2018-02-26-173754-943">
          Ontario Sample Bill
        </a>
      </Typography>
    ),
  },
  {
    title: 'Will my bill come directly from Just Energy?',
    content: (
      <Typography>
        In most cases, Just Energy does not bill separately from your utility
        for electricity or natural gas. Just Energy&apos;s charges will appear
        as a separate line item on your utility bill. Only customers in Texas,
        Georgia, California, and Alberta receive their bill directly from Just
        Energy.
      </Typography>
    ),
  },
  {
    title: 'Is Just Energy charging me for my supply only?',
    content: (
      <Typography>
        It depends on your plan. Some contracts may include supply only. With
        other products, the cost of JustGreen will be included in your commodity
        price. You will continue to remain responsible for the following utility
        charges: regulated delivery, transmission, transportation and
        supplemental gas. If you are a natural gas customer, in addition to your
        natural gas supply, Just Energy also charges you a variable
        transportation charge. Depending on the program you are on, additional
        Just Energy charges for JustGreen and other administrative charges may
        also apply. If you are an electricity customer, depending on the program
        you are on, additional charges for JustGreen and administration may
        apply. Refer to your Terms and Conditions for complete details.
      </Typography>
    ),
  },
  {
    title: 'Are there additional fees that my utility may be charging me?',
    content: (
      <Typography>
        Whether Just Energy provides your natural gas supply or not, you will
        still be responsible to the utility for taxes, delivery, transportation,
        mid-stream cost recovery and other costs billed by your utility. All
        natural gas customers, regardless of if they are with a retailer or the
        utility will be subject to the following utility charges: taxes,
        transmission, delivery debt retirement, uplift, congestion, storage and
        other service related charges. All electricity customers, regardless of
        if they are with a retailer or the utility will be subject to the
        following utility charges: regulated delivery, regulatory, debt
        retirement and other service related charges. As always, all electricity
        customers remain entitled to/bound by the Global Adjustment.
      </Typography>
    ),
  },
  {
    title:
      'Does Just Energy offer "budget billing"? Or a similar payment plan?',
    content: (
      <Typography>
        We offer budget-billing to our Alberta customers. Call us at
        1-866-926-3227 to enroll in budget billing.
      </Typography>
    ),
  },
  {
    title:
      'What are the benefits of making payments using a Pre-authorized Debit Plan?',
    content: (
      <Typography>
        With our Pre-Authorized Debit plan, there is no need to fuss with
        envelopes or stamps to pay your energy bills. Our program ensures that
        your payment will always arrive on time even when you&apos;re away.
        It&apos;s the fastest and safest way to pay your bill. Call our contact
        centre (1-866-587-8674) to enroll your account on this plan.
      </Typography>
    ),
  },
  {
    title: 'What is a "smart meter"?',
    content: (
      <Typography>
        A smart meter automatically tracks how much electricity your home uses
        and when it is used. This information appears on your monthly energy
        bills.
      </Typography>
    ),
  },
  {
    title: 'Do I have any options to avoid time-of-use pricing?',
    content: (
      <Typography>
        If you wish to avoid On-Peak and Mid-Peak rates, you should consider
        alternative plans from Just Energy. Please call us at 1-866-926-3227 to
        discuss your other plan options.
      </Typography>
    ),
  },
  {
    title:
      'What is the "electricity/natural gas Use At A Glance Chart" On My Statement Of Account?',
    content: (
      <Typography>
        It reflects historical usage linked with the sites. This information is
        obtained from your local utility.
      </Typography>
    ),
  },
  {
    title: 'How can I find my current account balance or see my current bill?',
    content: (
      <Typography>
        Please contact your local utility regarding inquiries relevant to your
        current account balance. For information concerning your energy supply
        price, please contact Just Energy directly.
      </Typography>
    ),
  },
  {
    title: 'How do I consolidate my account?',
    content: (
      <Typography>
        Please call our customer service number at 1-866-587-8674 to see if
        account consolidation requirements are met and we will process this
        request.
      </Typography>
    ),
  },
  {
    title: 'Why the GST amount on my SOA does not add up?',
    content: (
      <Typography>
        The GST amount on front/first page includes the sum of all GST charges
        on both gas and electricity charges as well as combines the admin fee.
        Whereas pages 2/3/4 shows GST charges on separate line items on subtotal
        of gas and electricity charges.
      </Typography>
    ),
  },
  {
    title: 'How much will I be charged If I end my contract early?',
    content: (
      <Typography>
        The amount varies depending on your supply plan and contract length.
        Contact Just Energy for more details on cancellation fees.
      </Typography>
    ),
  },
  {
    title: 'Who do I contact with questions regarding my bill?',
    content: (
      <Typography>
        If you live in Alberta, most questions regarding your bill can be
        answered simply by logging in to your Just Energy{' '}
        <Link to="/locations/alberta-electricity-rates/my-energy-world">
          online account
        </Link>
        . If you have any other questions regarding the commodity supply portion
        of your bill, please contact Just Energy using any of the following
        methods: Phone: <a href="tel:18665878674">1-866-587-8674</a> Email:
        cs@justenergy.com Please contact your local utility for questions
        relating to all other charges on your bill.
      </Typography>
    ),
  },
  {
    title: 'Where and how do I pay my energy bill?',
    content: (
      <Typography>
        You will receive your bill and can render payment directly with Just
        Energy. Consult your bill for payment options or check out our{' '}
        <Link to="/help">Payments page</Link> for more information. Contact your
        utility to determine what methods can be used to pay your bill.
      </Typography>
    ),
  },
  {
    title: 'Does Just Energy use a collection agency?',
    content: (
      <Typography>
        Yes, like most major companies, Just Energy does use a collection agency
        when necessary.
      </Typography>
    ),
  },
  {
    title:
      'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
    content: (
      <Typography>
        Just Energy will not, nor does it have the ability to, disconnect your
        electricity service. If you have been disconnected, contact your
        utility.
      </Typography>
    ),
  },
  {
    title: 'Will I be charged a penalty for disconnection?',
    content: (
      <Typography>
        Please contact our customer service team for further assistance. Contact
        your local utility to determine any disconnection penalties that may
        apply.
      </Typography>
    ),
  },
  {
    title:
      "What is Just Energy's mailing address for exit fees or documentation?",
    content: (
      <Typography>
        If you are sending us payment by mail, please send it to: Just Energy,
        P.O. Box 2210, Buffalo, NY 14240-2210.
      </Typography>
    ),
  },
  {
    title: 'What are some types of energy plans offered by retailers?',
    content: (
      <Typography>
        Typically, energy retailers offer price protected plans (allowing you to
        secure your commodity supply rate for a set period of time) and
        variable-rate plans (where the commodity supply rate would change with
        the changes in market prices for a set period of time).{' '}
        <Link to="/learning-center/price-volatility">Learn more</Link> about the
        benefits and why you would be interested in one plan over the other.
      </Typography>
    ),
  },
]

const common = []

export const billingPaymentData = {
  California: [
    ...common,
    ...US,
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments. California
          customers incur a late fee of 1.5% of the total past due amount.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility. California customers do not incur a disconnect notice fee.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Delaware: [
    ...common,
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
  ],
  Illinois: [
    ...common,
    ...US,
    {
      title: 'What is "budget billing"? How do I sign up for it?',
      content: (
        <Typography>
          Budget billing means that you pay a set amount each month for the
          bill. Typically, the company will look at the amount you spent last
          year and then add a percentage to it, to cover the cost of inflation
          and divide the amount over 12 months. You can contact your local
          utility to see if it offers budget billing in your area. Illinois
          customers can enroll in budget billing—Just Energy accounts for this
          option in the commodity portion of your utility bill.
        </Typography>
      ),
    },
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Indiana: [
    ...common,
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Maryland: [
    ...common,
    ...US,
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
  ],
  Massachusetts: [
    ...common,
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Michigan: [
    ...common,
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  New_Jersey: [
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  New_York: [
    ...common,
    ...US,
    {
      title: 'What is "budget billing"? How do I sign up for it?',
      content: (
        <Typography>
          Budget billing means that you pay a set amount each month for the
          bill. Typically, the company will look at the amount you spent last
          year and then add a percentage to it, to cover the cost of inflation
          and divide the amount over 12 months. You can contact your local
          utility to see if it offers budget billing in your area. New York
          customers can enroll in budget billing. Your local utility company
          will handle this service if you elect to enroll.
        </Typography>
      ),
    },
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Ohio: [
    ...common,
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Pennsylvania: [
    ...common,
    ...US,
    {
      title: 'Where and how do I pay my energy bill?',
      content: (
        <Typography>
          Just Energy does not bill separately from your utility bill in most
          states and territories, it is simply listed as your supplier. Contact
          your utility to determine what methods can be used to pay your bill.
        </Typography>
      ),
    },
    {
      title: "Is there a late fee if I don't pay my bill on time?",
      content: (
        <Typography>
          If your energy bill comes from your local utility company, contact
          them for more information regarding late payments.
        </Typography>
      ),
    },
    {
      title:
        'If my service has been disconnected for non-payment but I pay my balance, can I have my service reconnected?',
      content: (
        <Typography>
          Just Energy will not, nor does it have the ability to, disconnect your
          electricity service. If you have been disconnected, contact your
          utility.
        </Typography>
      ),
    },
    {
      title: 'Will I be charged a penalty for disconnection?',
      content: (
        <Typography>
          Contact your local utility to determine any disconnection penalties
          that may apply.
        </Typography>
      ),
    },
  ],
  Georgia: [...common, ...US],
  Texas: [
    ...common,
    ...US,
    {
      title: 'Why do I see a "TDU Surcharge" On My Bill?',
      content: (
        <Typography>
          The TDU Surcharge is a delivery charge that your local transmission
          and distribution utility (TDU) bills for delivering power to your home
          reliably. These charges are authorized by the Public Utility
          Commission of Texas (PUCT).
        </Typography>
      ),
    },
    {
      title: 'What is "budget billing"? How do I sign up for it?',
      content: (
        <Typography>
          Budget billing means that you pay a set amount each month for the
          bill. Typically, the company will look at the amount you spent last
          year and then add a percentage to it, to cover the cost of inflation
          and divide the amount over 12 months. You can contact your local
          utility to see if it offers budget billing in your area. If you have
          service in Texas, you can sign up for budget billing directly with
          Just Energy. Contact customer service for more information.
        </Typography>
      ),
    },
  ],
  Alberta: [...CA],
  British_Columbia: [...CA],
  Ontario: [...CA],
  Quebec: [...CA],
  Saskatchewan: [...CA],
  Manitoba: [...CA],
}
